import React from "react";

import Layout from "../components/layout";

const IndexPage = () => (
  <Layout>
    <div className="centerContent">
      <img alt="Karol Danko" src={require("../images/karoldanko.jpg")} />
    </div>
    <div className="centerContent">
      <h1>Karol Danko</h1>
      <div style={{ textAlign: "justify", lineHeight: "2em" }}>
        I'm a tech lead with a computer science background, long history in
        full-stack development and team leading. I have experience building /
        advising / helping early stage startups and large scale, long-running
        projects. I specialises in full-javascript stack - React/Node.js, but I
        always chooses the right technology to get the job done. I'm passionate
        about IoT, drones and DIY electronics.
      </div>
    </div>

    <div className="centerContent">
      <h2>Links</h2>
      <ol>
        <li>
          <a href="https://github.com/quibusus">GitHub</a>
        </li>
        <li>
          <a href="https://www.linkedin.com/in/karoldanko">LinkedIn</a>{" "}
        </li>
        <li>
          <a href="https://twitter.com/karoldanko">Twitter</a>
        </li>
        <li>
          <a href="https://angel.co/karoldanko">AngelList</a>
        </li>
      </ol>
    </div>

    <div className="centerContent">
      <h2>Talks</h2>
      <ol>
        <li>
          <a href="https://vimeo.com/391820644">
            (EN) DisruptHR Prague 2020 - HR Transformation In The Age Of
            DeepMind
          </a>
        </li>

        <li>
          <a href="https://slideslive.com/38918933/javascript-the-best-language-for-your-mvp">
            (EN) WebExpo 2019 - Why JavaScript is the best language for your MVP
          </a>
        </li>
        <li>
          <a href="https://slideslive.com/38921027/javascript-najlepsi-jazyk-pre-vas-startup">
            (SK) JavaScript - najlepší jazyk pre váš startup
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=wn2qjLI3NHs">
            (SK) Livesport talk - Scaling Node.js
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=x5tAf_brE6A">
            (SK) Livesport talk - Scaling data scrapers
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=POHkiDU6pc8">
            (SK) Linux days 2017 - IoT at home
          </a>
        </li>
        <li>
          <a href="https://slideslive.cz/38900590/">
            (SK) Devel.cz 2017 - IoT Button
          </a>
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=GN4VENUfV8M">
            (SK) pehapkari.cz - IoT at home
          </a>
        </li>
      </ol>
    </div>
    <div className="centerContent">
      <h2>Get in touch</h2>
      <span
        onClick={e => {
          // eslint-disable-next-line
          var m = "karol" + "@" + "karoldanko.com";
          e.target.innerHTML = '<a href="mailto:' + m + '">' + m + "</a>";
        }}
      >
        email - click to reveal
      </span>
    </div>
  </Layout>
);

export default IndexPage;
